import React from 'react'
import { Layout } from "../components/layout"

const AboutPage = () => {
    return (
        <Layout>
            <h2 as="h2" className="title">About</h2>
            <div className="container">
            <p>The goal of this site is to give our US customers multiple ways to submit their orders. Now they can easily do so using our shopify online store, <a href="https://www.ifi-test.com/shop/" rel="noopener nofollow" className="underline">the submission form on our main website</a>, or by <a href="https://www.ifi-test.com/order-form/" rel="noopener nofollow" className="underline">downloading and emailing our editable order form</a>.</p>
            <p>Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide. Our staff has over 60 combined years of experience in DNA, molecular biology, forensics, paternity, human identification and related scientific fields.</p>
            </div>
            <h2 className="title">Payment Processing</h2>
            <div className="container">
            <p><b>At this time, we are not accepting online payments, but it is something that we are considering to implement in the near future.</b> For now US customers can easily place online orders and expect payment to be collected after the order has been confirmed by our staff.</p>
            <p>All of your orders are securely processed via Shopify. Contact information is only accessible to us, Independent Forensics. We will never share any of your contact information to third parties.</p>
            </div>
            <h2 className="title">For International Customers</h2>
            <div className="container">
            <p>
            We only sell to authorized distributors. We protect and support all our distributors worldwide. US customers please order directly. Please contact us for any further inquiries.
            </p>
            </div>
            <h2 className="title">Shipping & Taxes</h2>
            <div className="container">
            <p>Please note that taxes(if applicable) and shipping costs will be calculated once we receive your order. We typically ship via FedEx Ground but next-day, two-day delivery and other options are available, please let us know by replying to your order confirmation. Minimum cost for shipping is $35.</p>
            </div>
            <h2 className="title">Order Processing</h2>
            <div className="container">
            <p>Online orders placed during office hours are processed as soon as they are placed. Orders placed during the weekend and or holidays will be processed the following business day.</p>
            </div>
        </Layout>
    )
}

export default AboutPage;